// @ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import OutsideClickHandler from "react-outside-click-handler";
import { createPortal } from "react-dom";
import bem from "@justpark/helpers/src/bem/bem";
import type { Node } from "react";
import ScrollLock from "react-scrolllock";
import useEscapeKeyWithOnCloseCallback from "../../hooks/useEscapeKeyWithOnCloseCallback";
import useModalHook from "../../hooks/useModalHook";
import "./modal.scss";

type Props = {
  children: Node;
  isOpen: boolean;
  portalParent?: string;
  onClose: () => void;
  id: string;
  closeOnOutsideClick?: boolean;
  newBackground?: boolean;
  fullscreenMobile?: boolean;
};

const Modal = ({
  children,
  isOpen,
  portalParent = process.env.NODE_ENV === "test" ? "body" : "#__next",
  onClose,
  id,
  closeOnOutsideClick = true,
  newBackground = false,
  fullscreenMobile = false
}: Props) => {
  const baseClass = "c-modal";
  const [portalInit, setPortalInit] = useState(false);
  const ref = useRef(null);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  useModalHook(isOpen, "modal-open");
  useEscapeKeyWithOnCloseCallback(onCloseRef, isOpen, closeOnOutsideClick);
  useEffect(() => {
    ref.current = document.querySelector(portalParent);
    if (ref.current) {
      setPortalInit(true);
    }
  }, [portalParent, setPortalInit]);
  const nodeRef = useRef(null);

  if (portalInit) {
    return createPortal(
      <>
        {isOpen ? (
          <div
            className={bem(baseClass, "overlay", {
              "new-background": newBackground
            })}
          />
        ) : null}
        <TransitionGroup>
          {isOpen ? (
            <CSSTransition
              classNames={bem(baseClass, "modal", {
                fullscreenMobile
              })}
              key={`${baseClass}${portalParent}`}
              timeout={{
                enter: 350,
                exit: 180
              }}
              nodeRef={nodeRef}
            >
              <ScrollLock>
                <div className={baseClass}>
                  <div
                    key={id}
                    className={bem(baseClass, "modal", {
                      fullscreenMobile
                    })}
                    ref={nodeRef}
                  >
                    <OutsideClickHandler
                      onOutsideClick={onClose}
                      disabled={
                        !closeOnOutsideClick || typeof onClose !== "function"
                      }
                      display="flex"
                    >
                      {children}
                    </OutsideClickHandler>
                  </div>
                </div>
              </ScrollLock>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </>,
      ref.current
    );
  }
  return null;
};

export default Modal;
