// @ts-nocheck
import { useCallback, useEffect } from "react";

const useEscapeKeyWithOnCloseCallback = (
  onCloseRef,
  isOpen,
  closeOnOutsideClick
) => {
  const onKeyDown = useCallback(
    (event: SyntheticKeyboardEvent<typeof window>) => {
      if (
        event.key === "Escape" &&
        closeOnOutsideClick &&
        typeof onCloseRef.current === "function"
      ) {
        onCloseRef.current();
      }
    },
    [onCloseRef, closeOnOutsideClick]
  );
  const removeEventListener = useCallback(() => {
    window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);
  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", onKeyDown);
    } else {
      removeEventListener();
    }
    return removeEventListener;
  }, [isOpen, onKeyDown, removeEventListener]);
};
export default useEscapeKeyWithOnCloseCallback;
